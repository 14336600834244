import { Component, OnInit } from '@angular/core';
import * as M from '../../../assets/js/materialize.js';
// import { TodoService } from 'src/app/services/todo.service.js';

@Component({
  selector: 'app-todo',
  templateUrl: './todo.component.html',
  styleUrls: ['./todo.component.css']
})
export class TodoComponent implements OnInit {

  // constructor( public todoService: TodoService ) { }

  ngOnInit() {
    const instance = M.Tabs.init( document.querySelectorAll('.tabs'), {} );
  }

}
