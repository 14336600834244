import { Component, OnInit } from '@angular/core';
import * as M from '../../../assets/js/materialize.js';
import { HidratacionService } from 'src/app/services/hidratacion.service.js';

@Component({
  selector: 'app-hidratacion',
  templateUrl: './hidratacion.component.html',
  styleUrls: ['./hidratacion.component.css']
})
export class HidratacionComponent implements OnInit {

  constructor( public hidratacionService: HidratacionService ) { }

  ngOnInit() {
    const instance = M.Tabs.init( document.querySelectorAll('.tabs'), {} );
  }

}
