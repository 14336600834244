import { Component, OnInit } from '@angular/core';
import * as M from '../../../assets/js/materialize.js';
// import { SolarService } from 'src/app/services/solar.service.js';

@Component({
  selector: 'app-solar',
  templateUrl: './solar.component.html',
  styleUrls: ['./solar.component.css']
})
export class SolarComponent implements OnInit {

  // constructor( public solarService: SolarService ) { }

  ngOnInit() {
    const instance = M.Tabs.init( document.querySelectorAll('.tabs'), {} );
  }

}
